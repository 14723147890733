import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/use-auth";
import SignIn from "../components/auth/SignIn";
import ParticlesComponent from "components/ui/particles";

const LoginPage = () => {
    const { isAuth } = useAuth();

    return isAuth ? (
        <>
            <ParticlesComponent id="particle" />
            <SignIn />
        </>
    ) : (
        <Navigate to="/" />
    );
};

export default LoginPage;
