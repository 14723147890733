import React, { useState } from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    CircularProgress,
    Snackbar,
    Alert,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import JSZip from "jszip";
import {
    Document,
    Packer,
    Paragraph,
    Table as DocTable,
    TableCell as DocTableCell,
    TableRow as DocTableRow,
    BorderStyle,
    HeadingLevel,
} from "docx";
import axios from "axios";

const SubmitForm = ({ back, submit, formData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [loading, setLoading] = useState(false);
    const toUpperCase = (text) => text.toUpperCase();
    const generateDocx = async (data) => {
        const doc = new Document({
            styles: {
                default: {
                    paragraph: {
                        spacing: { after: 200 },
                    },
                },
            },
            sections: [
                {
                    properties: {},
                    children: [
                        new Paragraph({
                            text: "Данные клиента",
                            heading: HeadingLevel.HEADING_1,
                            style: "default",
                        }),
                        new DocTable({
                            rows: [
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Точка оформления",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                top: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text:
                                                        data[0]?.tgChat
                                                            .tg_name ||
                                                        "Не указано",
                                                    style: "default",
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Название салона",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text:
                                                        data[0]?.salonName ||
                                                        "Не указано",
                                                    style: "default",
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Имя менеджера",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text:
                                                        data[0]?.managerName ||
                                                        "Не указано",
                                                    style: "default",
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Телефон менеджера",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text:
                                                        data[0]?.managerPhone ||
                                                        "Не указано",
                                                    style: "default",
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Контактная информация клиента",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: `ФИО: ${
                                                        data[1]
                                                            ?.clientFullName ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Телефон: ${
                                                        data[1]?.phoneNumber ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `ИИН: ${
                                                        data[1]?.iin ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Семейный статус: ${
                                                        data[1]
                                                            ?.maritalStatus ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Детали автомобиля",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: `Марка: ${
                                                        data[2]?.brand ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Модель: ${
                                                        data[2]?.model ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Год: ${
                                                        data[2]?.year ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Руль: ${
                                                        data[2]?.steering ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Объем двигателя: ${
                                                        data[2]?.engineVolume ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `VIN: ${toUpperCase(
                                                        data[2]?.vin ||
                                                            "Не указано"
                                                    )}`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Цена: ${
                                                        data[2]?.price ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Первоначальный взнос: ${
                                                        data[2]?.downPayment ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Адрес проживания",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: `Улица: ${
                                                        data[3]?.street ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Номер дома: ${
                                                        data[3]?.houseNumber ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Квартира: ${
                                                        data[3]?.apartment ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Подъезд: ${
                                                        data[3]?.entrance ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Место работы",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: `Место работы: ${
                                                        data[4]?.workPlace ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Адрес работы: ${
                                                        data[4]?.workAddress ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                                new Paragraph({
                                                    text: `Контакты работы: ${
                                                        data[4]?.workContacts ||
                                                        "Не указано"
                                                    }`,
                                                    style: "default",
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                                new DocTableRow({
                                    children: [
                                        new DocTableCell({
                                            children: [
                                                new Paragraph({
                                                    text: "Экстренные контакты",
                                                    style: "default",
                                                }),
                                            ],
                                            borders: {
                                                bottom: {
                                                    style: BorderStyle.SINGLE,
                                                    size: 1,
                                                },
                                            },
                                        }),
                                        new DocTableCell({
                                            children: [
                                                ...data[5].map(
                                                    (contact, index) =>
                                                        new Paragraph({
                                                            text: `Контакт ${
                                                                index + 1
                                                            }: Имя: ${
                                                                contact.name ||
                                                                "Не указано"
                                                            }, Телефон: ${
                                                                contact.phone ||
                                                                "Не указано"
                                                            }`,
                                                            style: "default",
                                                        })
                                                ),
                                            ],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    ],
                },
            ],
        });

        const blob = await Packer.toBlob(doc);
        return blob;
    };

    const sendFileToTelegram = async (chatId, zipBlob, clientFullName) => {
        const TELEGRAM_BOT_TOKEN =
            "7096711434:AAFY4j20zvKi5C38FWw7VUbwAmh7KygjU7E";
        const TELEGRAM_API_URL = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendDocument`;

        const formData = new FormData();
        formData.append("chat_id", chatId);
        formData.append(
            "document",
            new File([zipBlob], `${clientFullName}.zip`)
        );

        try {
            await axios.post(TELEGRAM_API_URL, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleSubmit = async () => {
        setLoading(true); // Start loading animation

        const zip = new JSZip();

        // Generate the DOCX file
        const docBlob = await generateDocx(formData);
        zip.file("Данные клиента.docx", docBlob);

        // Add uploaded documents to the ZIP
        const dataFolder = zip.folder("Данные");
        const documentSteps = [
            { key: "idDocument", label: "Удостоверение" },
            { key: "techPassport", label: "Техпаспорт" },
            { key: "bankStatement", label: "Выписка" },
        ];
        documentSteps.forEach(({ key, label }) => {
            const files = formData[6]?.[key];
            if (Array.isArray(files) && files.length > 0) {
                const file = files[0];
                if (file instanceof Blob) {
                    dataFolder.file(`${label}.pdf`, file);
                }
            }
        });
        // Добавление фото из formData[7]?.photo
        const photo = formData[7]?.photo;
        if (photo) {
            const photoBlob = await fetch(photo.data).then((res) => res.blob());
            dataFolder.file(photo.name, photoBlob);
        }

        // Generate ZIP file
        const zipBlob = await zip.generateAsync({ type: "blob" });
        const clientFullName = formData[1]?.clientFullName || "client";

        // Send the ZIP file to Telegram
        const success = await sendFileToTelegram(
            formData[0]?.tgChat.tg_id, //Отправка на тот канал который выбрал пользователь
            zipBlob,
            clientFullName
        );

        setLoading(false); // Stop loading animation

        setSnackbarMessage(
            success
                ? "Данные успешно отправлены!"
                : "Ошибка при отправке данных."
        );
        setSnackbarSeverity(success ? "success" : "error");
        setSnackbarOpen(true);

        // Call the callback to switch to the result form
        submit(success);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    console.log(formData);
    return (
        <Box sx={{ padding: isMobile ? 2 : 3 }}>
            <Typography
                variant="h6"
                sx={{ marginBottom: 2, textAlign: "center" }}
            >
                Проверьте ваши данные
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Категория</TableCell>
                            <TableCell>Данные</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Точка оформления</TableCell>
                            <TableCell>
                                {formData[0]?.tgChat.tg_name || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Название салона</TableCell>
                            <TableCell>
                                {formData[0]?.salonName || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Имя менеджера</TableCell>
                            <TableCell>
                                {formData[0]?.managerName || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Телефон менеджера</TableCell>
                            <TableCell>
                                {formData[0]?.managerPhone || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>ФИО клиента</TableCell>
                            <TableCell>
                                {formData[1]?.clientFullName || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Телефон клиента</TableCell>
                            <TableCell>
                                {formData[1]?.phoneNumber || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>ИИН клиента</TableCell>
                            <TableCell>
                                {formData[1]?.iin || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Семейный статус</TableCell>
                            <TableCell>
                                {formData[1]?.maritalStatus || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Марка автомобиля</TableCell>
                            <TableCell>
                                {formData[2]?.brand || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Модель автомобиля</TableCell>
                            <TableCell>
                                {formData[2]?.model || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Год выпуска</TableCell>
                            <TableCell>
                                {formData[2]?.year || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Руль</TableCell>
                            <TableCell>
                                {formData[2]?.steering || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Объем двигателя</TableCell>
                            <TableCell>
                                {formData[2]?.engineVolume || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>VIN</TableCell>
                            <TableCell sx={{ textTransform: "uppercase" }}>
                                {formData[2]?.vin || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Цена</TableCell>
                            <TableCell>
                                {formData[2]?.price || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Первоначальный взнос</TableCell>
                            <TableCell>
                                {formData[2]?.downPayment || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Улица</TableCell>
                            <TableCell>
                                {formData[3]?.street || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Номер дома</TableCell>
                            <TableCell>
                                {formData[3]?.houseNumber || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Квартира</TableCell>
                            <TableCell>
                                {formData[3]?.apartment || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Подъезд</TableCell>
                            <TableCell>
                                {formData[3]?.entrance || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Место работы</TableCell>
                            <TableCell>
                                {formData[4]?.workPlace || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Адрес работы</TableCell>
                            <TableCell>
                                {formData[4]?.workAddress || "Не указано"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Контакты работы</TableCell>
                            <TableCell>
                                {formData[4]?.workContacts || "Не указано"}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2} align="center">
                                Экстренные контакты
                            </TableCell>
                        </TableRow>
                        {formData[5]?.map((contact, index) => (
                            <TableRow key={index}>
                                <TableCell>{`Контакт ${index + 1}`}</TableCell>
                                <TableCell>{`Имя: ${
                                    contact.name || "Не указано"
                                }, Телефон: ${
                                    contact.phone || "Не указано"
                                }`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={back}
                    disabled={loading}
                >
                    Назад
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        "Отправить"
                    )}
                </Button>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SubmitForm;
