import React, { useState } from "react";
import {
    Box,
    Container,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import SalonInformation from "./steps/SalonInformation";
import ClientContactInfo from "./steps/ClientContactInfo";
import SubmitForm from "./steps/SubmitForm";
import CarDetails from "./steps/CarDetails";
import EmergencyContacts from "./steps/EmergencyContacts";
import WorkDetails from "./steps/WorkDetails";
import ResidentialAddress from "./steps/ResidentialAddress";
import UploadDocuments from "./steps/UploadsDocuments";
import CapturePhoto from "./steps/CapturePhoto";
import ConsentForm from "./steps/ConsentForm";
import SubmissionResult from "./steps/SubmissionResult";

const steps = [
    "Информация салона",
    "Контактная информация клиента",
    "Детали автомобиля",
    "Адрес проживания",
    "Место работы",
    "Экстренные контакты",
    "Загрузка документов",
    "Фото клиента",
    "Согласие на сбор данных",
    "Отправить",
];

const MultiStepForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [direction, setDirection] = useState(1);
    const [submissionResult, setSubmissionResult] = useState(null);

    const handleNext = () => {
        setDirection(1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setDirection(-1);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDataChange = (step, data) => {
        setFormData({ ...formData, [step]: data });
    };

    const handleSubmit = async () => {
        const success = true; // Replace with actual success logic
        setSubmissionResult(success);
        setActiveStep(steps.length);
    };

    const handleRetry = () => {
        setFormData({});
        setSubmissionResult(null);
        setActiveStep(0);
    };

    return (
        <Container maxWidth={isMobile ? "s" : "lg"}>
            <Typography variant="h4" align="center" gutterBottom>
                Форма отправки данных
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    overflowX: "auto",
                    marginBottom: 2,
                }}
            >
                <Stepper activeStep={activeStep} alternativeLabel={!isMobile}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2}>
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={activeStep}
                        custom={direction}
                        initial={{
                            opacity: 0,
                            x: direction > 0 ? -10 : 10,
                        }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{
                            opacity: 0,
                            x: direction > 0 ? 10 : -10,
                        }}
                        transition={{ duration: 0.5 }}
                    >
                        {getStepContent(
                            activeStep,
                            handleNext,
                            handleBack,
                            handleDataChange,
                            formData,
                            handleSubmit,
                            handleRetry,
                            submissionResult
                        )}
                    </motion.div>
                </AnimatePresence>
            </Box>
        </Container>
    );
};

const getStepContent = (
    step,
    handleNext,
    handleBack,
    handleDataChange,
    formData,
    handleSubmit,
    handleRetry,
    submissionResult
) => {
    if (step === steps.length) {
        return (
            <SubmissionResult
                success={submissionResult}
                onRetry={handleRetry}
            />
        );
    }

    switch (step) {
        case 0:
            return (
                <SalonInformation
                    next={handleNext}
                    dataChange={handleDataChange}
                    formData={formData[0]}
                />
            );
        case 1:
            return (
                <ClientContactInfo
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[1]}
                />
            );
        case 2:
            return (
                <CarDetails
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[2]}
                />
            );
        case 3:
            return (
                <ResidentialAddress
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[3]}
                />
            );
        case 4:
            return (
                <WorkDetails
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[4]}
                />
            );
        case 5:
            return (
                <EmergencyContacts
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[5]}
                />
            );
        case 6:
            return (
                <UploadDocuments
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[6]}
                />
            );
        case 7:
            return (
                <CapturePhoto
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={formData[7]}
                    clientName={formData[1].clientFullName}
                />
            );
        case 8:
            return (
                <ConsentForm
                    next={handleNext}
                    back={handleBack}
                    dataChange={handleDataChange}
                    formData={{
                        ...formData[8],
                        fullName: formData[1]?.clientFullName,
                        iin: formData[1]?.iin,
                    }}
                />
            );
        case 9:
            return (
                <SubmitForm
                    back={handleBack}
                    submit={handleSubmit}
                    formData={formData}
                />
            );
        default:
            return "Unknown step";
    }
};

export default MultiStepForm;
