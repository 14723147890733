import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

const CarDetails = ({ next, back, dataChange, formData = {} }) => {
  const [brand, setBrand] = useState(formData.brand || "");
  const [model, setModel] = useState(formData.model || "");
  const [year, setYear] = useState(formData.year || "");
  const [steering, setSteering] = useState(formData.steering || "");
  const [engineVolume, setEngineVolume] = useState(formData.engineVolume || "");
  const [vin, setVin] = useState(formData.vin || "");
  const [price, setPrice] = useState(formData.price || "");
  const [downPayment, setDownPayment] = useState(formData.downPayment || "");
  const [errors, setErrors] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const validateForm = () => {
    const newErrors = {};
    if (!brand.trim()) newErrors.brand = "Марка автомобиля обязательна";
    if (!model.trim()) newErrors.model = "Модель автомобиля обязательна";
    if (!year.trim() || year.length !== 4)
      newErrors.year = "Год выпуска обязателен и должен содержать 4 цифры";
    if (!steering.trim()) newErrors.steering = "Выбор руля обязателен";
    if (!engineVolume.trim())
      newErrors.engineVolume = "Объем двигателя обязателен";
    if (!vin.trim() || vin.length !== 17)
      newErrors.vin = "VIN-код обязателен и должен содержать 17 символов";
    if (!price.trim()) newErrors.price = "Стоимость автомобиля обязательна";
    if (!downPayment.trim())
      newErrors.downPayment = "Сумма внесения обязательна";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      dataChange(2, {
        brand,
        model,
        year,
        steering,
        engineVolume,
        vin,
        price,
        downPayment,
      });
      next();
    }
  };

  const calculateDifference = () => {
    const priceNumber = parseFloat(price.replace(/\s/g, ""));
    const downPaymentNumber = parseFloat(downPayment.replace(/\s/g, ""));
    if (!isNaN(priceNumber) && !isNaN(downPaymentNumber)) {
      return (priceNumber - downPaymentNumber).toLocaleString();
    }
    return 0;
  };

  const calculatePercentage = () => {
    const priceNumber = parseFloat(price.replace(/\s/g, ""));
    const downPaymentNumber = parseFloat(downPayment.replace(/\s/g, ""));
    if (!isNaN(priceNumber) && !isNaN(downPaymentNumber) && priceNumber !== 0) {
      return ((downPaymentNumber / priceNumber) * 100).toFixed(2);
    }
    return 0;
  };

  return (
    <Box sx={{ padding: isMobile ? 2 : 3 }}>
      <Typography variant="h6">Детали автомобиля</Typography>
      <TextField
        label="Марка"
        value={brand}
        onChange={(e) => setBrand(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.brand}
        helperText={errors.brand}
      />
      <TextField
        label="Модель"
        value={model}
        onChange={(e) => setModel(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.model}
        helperText={errors.model}
      />
      <TextField
        label="Год выпуска"
        value={year}
        onChange={(e) => {
          const input = e.target.value;
          if (/^\d{0,4}$/.test(input)) {
            setYear(input);
          }
        }}
        fullWidth
        margin="normal"
        required
        type="number"
        error={!!errors.year}
        helperText={errors.year}
      />
      <TextField
        select
        label="Руль"
        value={steering}
        onChange={(e) => setSteering(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.steering}
        helperText={errors.steering}
      >
        <MenuItem value="Левый">Левый</MenuItem>
        <MenuItem value="Правый">Правый</MenuItem>
      </TextField>
      <NumericFormat
        customInput={TextField}
        decimalScale={1}
        decimalSeparator=","
        label="Объем двигателя (л)"
        value={engineVolume}
        onChange={(e) => setEngineVolume(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.engineVolume}
        helperText={errors.engineVolume}
      />
      <TextField
        label="VIN-код"
        value={vin}
        onChange={(e) => setVin(e.target.value)}
        fullWidth
        margin="normal"
        required
        inputProps={{ maxLength: 17, style: { textTransform: "uppercase" } }}
        error={!!errors.vin}
        helperText={errors.vin}
      />
      <NumericFormat
        customInput={TextField}
        thousandSeparator=" "
        label="Стоимость автомобиля (₸)"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.price}
        helperText={errors.price}
      />
      <NumericFormat
        customInput={TextField}
        thousandSeparator=" "
        label="Первоначальный взнос (₸)"
        value={downPayment}
        onChange={(e) => setDownPayment(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.downPayment}
        helperText={errors.downPayment}
      />
      <Typography variant="body1">
        {`Первоначальный взнос: ${calculatePercentage()}%`}
      </Typography>
      <Typography variant="body1">
        {`Оставшаяся сумма: ${calculateDifference()} ₸`}
      </Typography>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={back}>
          Вернуться
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Продолжить
        </Button>
      </Box>
    </Box>
  );
};

export default CarDetails;
