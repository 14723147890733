import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { removeUser } from "store/slices/userSlice";
import { useAuth } from "hooks/use-auth";

import logo from "assets/logo.png"; // Замените на корректный путь к логотипу

const Header = () => {
    const dispatch = useDispatch();
    const { isAuth, email } = useAuth();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate("/");
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                dispatch(removeUser());
            })
            .catch((error) => {
                console.error("Error signing out:", error);
            });
    };

    const drawer = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button component="a" href="tel:+77076367414">
                    <ListItemText primary="Техподдержка: +7 707 636 74 14" />
                </ListItem>
                {isAuth ? (
                    <>
                        <ListItem>
                            <ListItemText primary={`Вы вошли как ${email}`} />
                        </ListItem>
                        <ListItem button onClick={handleLogout}>
                            <ListItemText primary="Выйти" />
                        </ListItem>
                    </>
                ) : (
                    <ListItem>
                        <ListItemText primary="Не авторизованы" />
                    </ListItem>
                )}
            </List>
        </Box>
    );

    return (
        <AppBar
            position="static"
            sx={{ mb: 2, backgroundColor: "rgba(0, 0, 0, 0.7)" }}
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={handleLogoClick}
                >
                    <img
                        src={logo}
                        alt="Logo"
                        style={{
                            marginRight: 16,
                            width: isMobile ? 50 : 100,
                            height: "auto",
                        }}
                    />
                    <Typography noWrap sx={{ color: "white" }}>
                        AVTOMARKET FINANCE
                    </Typography>
                </Box>
                {isMobile ? (
                    <>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            {drawer}
                        </Drawer>
                    </>
                ) : (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button color="inherit" href="tel:+77076367414">
                            Техподдержка: +7 707 636 74 14
                        </Button>
                        {isAuth ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    ml: 2,
                                }}
                            >
                                <Typography variant="body1" sx={{ ml: 2 }}>
                                    Вы вошли как {email}
                                </Typography>
                                <Button
                                    color="inherit"
                                    onClick={handleLogout}
                                    sx={{ ml: 2 }}
                                >
                                    Выйти
                                </Button>
                            </Box>
                        ) : (
                            <Typography variant="body1" sx={{ ml: 2 }}>
                                Не авторизованы
                            </Typography>
                        )}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
