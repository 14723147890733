import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const ResidentialAddress = ({ next, back, dataChange, formData = {} }) => {
  const [street, setStreet] = useState(formData.street || "");
  const [houseNumber, setHouseNumber] = useState(formData.houseNumber || "");
  const [apartment, setApartment] = useState(formData.apartment || "");
  const [entrance, setEntrance] = useState(formData.entrance || "");
  const [errors, setErrors] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const validateForm = () => {
    const newErrors = {};
    if (!street.trim()) newErrors.street = "Улица обязательна";
    if (!houseNumber.trim()) newErrors.houseNumber = "Дом обязателен";
    if (!apartment.trim()) newErrors.apartment = "Квартира обязательна";
    if (!entrance.trim()) newErrors.entrance = "Подъезд обязателен";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      dataChange(3, { street, houseNumber, apartment, entrance });
      next();
    }
  };

  return (
    <Box sx={{ padding: isMobile ? 2 : 3 }}>
      <Typography variant="h6">Адрес проживания</Typography>
      <TextField
        label="Улица"
        value={street}
        onChange={(e) => setStreet(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.street}
        helperText={errors.street}
      />
      <TextField
        label="Дом"
        value={houseNumber}
        onChange={(e) => setHouseNumber(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.houseNumber}
        helperText={errors.houseNumber}
      />
      <TextField
        label="Квартира"
        value={apartment}
        onChange={(e) => setApartment(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.apartment}
        helperText={errors.apartment}
      />
      <TextField
        label="Подъезд"
        value={entrance}
        onChange={(e) => setEntrance(e.target.value)}
        fullWidth
        margin="normal"
        required
        error={!!errors.entrance}
        helperText={errors.entrance}
      />
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={back}>
          Вернуться
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Продолжить
        </Button>
      </Box>
    </Box>
  );
};

export default ResidentialAddress;
