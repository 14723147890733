import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DocumentContent from "./DocumentContent";

const ConsentForm = ({ next, back, dataChange, formData = {} }) => {
  const [consentGiven, setConsentGiven] = useState(
    formData.consentGiven || false
  );
  const [open, setOpen] = useState(false);

  const handleConsentChange = (event) => {
    setConsentGiven(event.target.checked);
  };

  const handleNext = () => {
    dataChange(8, { consentGiven });
    next();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6">Согласие на сбор данных</Typography>
      <Box mt={2}>
        <Typography>
          <Button variant="outlined" onClick={handleClickOpen}>
            Нажмите здесь, чтобы прочитать документ согласия на сбор данных.
          </Button>
        </Typography>
        <FormControlLabel
          control={
            <Checkbox checked={consentGiven} onChange={handleConsentChange} />
          }
          label="Я согласен(на) на сбор данных"
        />
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={back}>
          Вернуться
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={!consentGiven}
        >
          Продолжить
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Документ согласия на сбор данных</DialogTitle>
        <DialogContent>
          <DocumentContent formData={formData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConsentForm;
