import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const CapturePhoto = ({
  next,
  back,
  dataChange,
  formData = {},
  clientName,
}) => {
  const [photo, setPhoto] = useState(formData.photo || null);
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameraAvailable(videoDevices.length > 0);
      } catch (error) {
        console.error("Error checking camera availability:", error);
        setCameraAvailable(false);
      }
    };

    checkCameraAvailability();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setPhoto({ name: `photo_${clientName}.jpeg`, data: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const handleNext = () => {
    dataChange(7, { photo });
    next();
  };

  return (
    <Box sx={{ padding: isMobile ? 2 : 3 }}>
      <Typography variant="h6">Фото клиента</Typography>
      {!photo && (
        <>
          {cameraAvailable ? (
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="upload-photo"
              type="file"
              capture="environment"
              onChange={handleFileChange}
            />
          ) : (
            <>
              <Typography color="error" gutterBottom>
                Камера не обнаружена. Пожалуйста, загрузите фото.
              </Typography>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="upload-photo"
                type="file"
                onChange={handleFileChange}
              />
            </>
          )}
          <label htmlFor="upload-photo">
            <Button variant="contained" component="span">
              Загрузить фото
            </Button>
          </label>
        </>
      )}
      {photo && (
        <Box mt={2} textAlign="center">
          <img src={photo.data} alt="captured" style={{ maxWidth: "100%" }} />
          <IconButton onClick={() => setPhoto(null)}>
            <Delete />
          </IconButton>
        </Box>
      )}
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={back}>
          Вернуться
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Продолжить
        </Button>
      </Box>
    </Box>
  );
};

export default CapturePhoto;
