import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.css";

import { store } from "./store";
import "./firebase";
import App from "./App";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#f7f00cfa",
        },
        text: {
            background: "#FFFFFF",
            primary: "#FFFFFF",
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Provider store={store}>
                <BrowserRouter basename="/react-avto-market">
                    <App />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
