import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";

const EmergencyContacts = ({ next, back, dataChange, formData = [] }) => {
  const [contacts, setContacts] = useState(
    formData.length ? formData : [{ name: "", phone: "" }]
  );
  const [errors, setErrors] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (formData.length) {
      setContacts(formData);
    }
  }, [formData]);

  const handleAddContact = () => {
    if (contacts.length < 3) {
      setContacts([...contacts, { name: "", phone: "" }]);
      setErrors([...errors, { name: "", phone: "" }]);
    }
  };

  const handleRemoveContact = (index) => {
    setContacts(contacts.filter((_, i) => i !== index));
    setErrors(errors.filter((_, i) => i !== index));
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = contacts.map((contact, i) =>
      i === index ? { ...contact, [field]: value } : contact
    );
    setContacts(updatedContacts);

    const updatedErrors = errors.map((error, i) =>
      i === index
        ? { ...error, [field]: value ? "" : "Это поле обязательно" }
        : error
    );
    setErrors(updatedErrors);
  };

  const validateForm = () => {
    const newErrors = contacts.map((contact) => ({
      name: contact.name.trim() ? "" : "Это поле обязательно",
      phone:
        contact.phone.trim().length === 15
          ? ""
          : "Телефон должен быть заполнен до 15 символов",
    }));
    setErrors(newErrors);
    return newErrors.every((error) => !error.name && !error.phone);
  };

  const handleNext = () => {
    if (validateForm()) {
      dataChange(5, contacts);
      next();
    }
  };

  return (
    <Box sx={{ padding: isMobile ? 2 : 3 }}>
      <Typography variant="h6">Экстренные контакты</Typography>
      {contacts.map((contact, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb={2}
        >
          <TextField
            label="ФИО"
            value={contact.name}
            onChange={(e) => handleContactChange(index, "name", e.target.value)}
            fullWidth
            margin="normal"
            required
            error={!!errors[index]?.name}
            helperText={errors[index]?.name}
          />
          <MuiTelInput
            defaultCountry="KZ"
            onlyCountries={["KZ"]}
            label="Телефон"
            value={contact.phone}
            onChange={(newPhone) =>
              handleContactChange(index, "phone", newPhone)
            }
            fullWidth
            inputProps={{ maxLength: 15 }}
            margin="normal"
            required
            error={!!errors[index]?.phone}
            helperText={errors[index]?.phone}
          />
          {contacts.length > 1 && (
            <IconButton
              onClick={() => handleRemoveContact(index)}
              disabled={contacts.length === 1}
            >
              <RemoveCircle />
            </IconButton>
          )}
        </Box>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddCircle />}
        onClick={handleAddContact}
        disabled={contacts.length >= 3}
      >
        Добавить контакт
      </Button>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={back}>
          Вернуться
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Продолжить
        </Button>
      </Box>
    </Box>
  );
};

export default EmergencyContacts;
