import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Delete } from "@mui/icons-material";

const UploadDocuments = ({ next, back, dataChange, formData = {} }) => {
  const [idDocument, setIdDocument] = useState(formData.idDocument || []);
  const [techPassport, setTechPassport] = useState(formData.techPassport || []);
  const [bankStatement, setBankStatement] = useState(
    formData.bankStatement || []
  );
  const [errors, setErrors] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (formData.idDocument) setIdDocument(formData.idDocument);
    if (formData.techPassport) setTechPassport(formData.techPassport);
    if (formData.bankStatement) setBankStatement(formData.bankStatement);
  }, [formData]);

  const onDrop = (acceptedFiles, setFiles, label) => {
    const renamedFiles = acceptedFiles.map((file) => {
      const fileExtension = file.name.split(".").pop();
      const newName = `${label}.${fileExtension}`;
      return new File([file], newName, { type: file.type });
    });
    setFiles(renamedFiles.slice(0, 1)); // ограничиваем количество файлов до одного
  };

  const handleRemoveFile = (file, setFiles) => {
    setFiles([]);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!idDocument.length) newErrors.idDocument = "Удостоверение обязательно";
    if (!techPassport.length) newErrors.techPassport = "Техпаспорт обязателен";
    if (!bankStatement.length)
      newErrors.bankStatement = "Выписка с БВУ обязательна";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      dataChange(6, { idDocument, techPassport, bankStatement });
      next();
    }
  };

  const Dropzone = ({ label, files, setFiles, error, inputLabel }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, setFiles, inputLabel),
      accept: { "application/pdf": [] },
    });

    return (
      <>
        <Typography variant="h6">{label}</Typography>
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed grey",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
            marginTop: 2,
            marginBottom: 2,
            borderColor: error ? "red" : "grey",
          }}
        >
          <input {...getInputProps()} />
          <Typography>Перетащите файлы сюда или нажмите для выбора</Typography>
          <Typography variant="body2">Допустимый формат: .PDF</Typography>
        </Box>
        {error && <Typography color="error">{error}</Typography>}
        <List>
          {files.map((file, index) => (
            <ListItem key={index}>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => handleRemoveFile(file, setFiles)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  return (
    <Box sx={{ padding: isMobile ? 2 : 3 }}>
      <Dropzone
        label="Удостоверение"
        files={idDocument}
        setFiles={setIdDocument}
        error={errors.idDocument}
        inputLabel="Удостоверение"
      />
      <Dropzone
        label="Техпаспорт"
        files={techPassport}
        setFiles={setTechPassport}
        error={errors.techPassport}
        inputLabel="Техпаспорт"
      />
      <Dropzone
        label="Выписка с БВУ за последние 6 месяцев"
        files={bankStatement}
        setFiles={setBankStatement}
        error={errors.bankStatement}
        inputLabel="Выписка_с_БВУ"
      />
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={back}>
          Вернуться
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Продолжить
        </Button>
      </Box>
    </Box>
  );
};

export default UploadDocuments;
