import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCQX6kt6gP5dr8bkkykejVFZSrNRYbaIFc",
    authDomain: "react-avto-market-72594.firebaseapp.com",
    projectId: "react-avto-market-72594",
    storageBucket: "react-avto-market-72594.appspot.com",
    messagingSenderId: "921700320059",
    appId: "1:921700320059:web:6beaea3210612f19716d1e",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
